.header {
    @include clearfix;
    @include zindex(overlay);

    width: 100%;

    @include breakpoint(xmedium) {
        padding-bottom: 0;
    }

    .row {
        margin-bottom: 0;
    }

    .column {
        @include breakpoint(medium down) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__content {
        position: relative;
        padding: $spacing;
    }

    .logo {
        display: inline-block;
        max-width: 50%;

        svg {
            display: block;
            max-width: 121px;
            max-height: 79px;
        }
    }

    &--nl-landingpage {
        .logo {
            color: color('white');
        }
    }

    .bg--header-image .logo {
        margin-top: 2.8em;

        @include breakpoint(medium) {
            margin-top: 0;
        }

        svg {
            visibility: hidden;
        }
    }

    .btn--login {
        display: none;
        background-color: #187fc2;

        @include breakpoint(xmedium) {
            display: block;
            position: absolute;
            top: 0;
            left: percentage(1 / 3);
            z-index: 1;
            height: $utility-nav-link-height;

            &,
            &::after {
                padding-top: 0.68em;
                padding-bottom: 0.68em;
            }
        }

        &::after {
            background-color: #0a4270;
        }

        @include hover {
            &::after {
                background-color: #187fc2;
            }
        }
    }

    .btn--ecr-tag {
        margin-bottom: $spacing / 2;
        width: 100%;
        text-align: left;

        @include breakpoint(xmedium) {
            @include zindex(overlay, 1);

            position: absolute;
            right: 0;
            bottom: $spacing;
            margin-bottom: 0;
            width: auto;
        }

    }

    .btn--sustainable-tag {
        bottom: -2em;
        margin-bottom: $spacing / 2;
        background-color: #ffae00;
        width: 100%;
        text-align: left;
        font-weight: 700;

        @include breakpoint(xmedium) {
            @include zindex(overlay, 1);

            position: absolute;
            right: 0;
            margin-bottom: 0;
            width: auto;
        }


        &::after {
            background-color: #eb9303;
        }

        @include hover {
            &::after {
                background-color: #ffae00;
            }
        }
    }
}

.primary-nav__links,
.utility-nav__links {
    display: inline-block;

    ul {
        display: inline-flex;
        justify-content: space-between;

        li {
            margin-top: 0;
            padding-left: $spacing;
            color: color('bluey-grey');

            &::before {
                display: block;
                position: absolute;
                top: 50%;
                left: $spacing / 2;
                transform: translate(-50%, -50%);
                margin-left: auto;
                border: 0;
                border-radius: 0;
                background: 0;
                width: auto;
                height: auto;
                line-height: $base-line-height;
                color: inherit;
                content: '|';
            }

            &:first-child {
                padding-left: $spacing / 2;

                &::before {
                    left: 0;
                }
            }

            a {
                @include font-special;

                padding: $utility-nav-link-height / 4 0;
                height: $utility-nav-link-height;
                color: inherit;
                font-weight: 500;

                &,
                &[target='_blank'] {
                    display: block;
                }

                svg {
                    width: 79px;
                    height: $utility-nav-link-height / 2;
                }
            }
        }
    }
}

.primary-nav__links {
    display: block;
    margin-top: $spacing * 2;

    ul {
        justify-content: flex-end;
        padding-right: 1rem;
        width: 100%;

        li {
            &:first-child::before {
                display: none;
            }

            a {
                padding: 13px 0;

                svg {
                    width: 56px;
                    height: 1em;
                }
            }
        }
    }
}

.utility-nav__links a {
    @include hover {
        color: color('battleship-grey');
    }
}

.headroom {
    /* Add a transition for the header itself if needed */
    transition: transform 0.3s ease;

    .btn--sustainable-tag {
        /* Set up base properties for the button */
        position: absolute;
        /* Make sure it has a position property for transform to work properly */
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.headroom--not-top {
        .btn--sustainable-tag {
            /* Instead of display: none, use these properties */
            transform: translateY(-10px);
            transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0.3s;
            /* Set visibility to hidden after the transition completes */
            visibility: hidden;
            opacity: 0;
        }
    }

    &.headroom--fixed {
        .btn--sustainable-tag {
            /* Instead of display: block, use these properties */
            transform: translateY(0);
            transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s;
            visibility: visible;
            /* Reset the visibility delay to 0s */
            opacity: 1;
        }
    }
}
